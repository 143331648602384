import * as React from "react"

import { AppStateContext } from "../../../utils/PageWrapper"
import { Col, Row } from "react-flexbox-grid"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _h2, _h3 } from "../../shared/headline.styles"
import { _processExternalLink } from "../../shared/links.styles"
import { MediaCarousel } from "../../shared"
import Hexagon3 from "../../../images/v2/hexagon-2.svg"
import styled from "styled-components"
import ImageBlur from "../../../images/v2/image-blur.svg"

interface RadarCarouselProps {
    title: string | JSX.Element
    lead: string
    link: {
        href: string
        title: string
    }
    items: ItemProps[]
}

interface ItemProps {
    type: string
    url: string
}

const _svgImage = styled.img`
    position: absolute;
    top: -140px;
    right: 95%;
    z-index: -1;
`

const _blur = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    top: -95px;
`

export const RadarCarousel: React.FC<RadarCarouselProps> = ({ title, items, lead, link }) => {
    const appState = React.useContext(AppStateContext)

    const desktopContent = (
        <>
            <_svgImage src={Hexagon3} />
            <Row center="xs">
                <Col lg={8} sm={12}>
                    <_h2 fontSize={24} marginBottom={1}>
                        {title}
                    </_h2>
                    <div style={{ marginBottom: "3em", position: "relative" }}>
                        <_blur src={ImageBlur} />
                        <MediaCarousel items={items} />
                    </div>

                    {lead ? (
                        <_Blockquote maxWidth={"540px"} marginLeft="auto" marginRight="auto" align={"center"}>
                            {lead}
                        </_Blockquote>
                    ) : null}
                    <_processExternalLink href={link.href}>{link.title}</_processExternalLink>
                </Col>
            </Row>
        </>
    )

    const mobileContent = (
        <Row>
            <Col lg={8} sm={12}>
                <_h2 fontSize={24}>{title}</_h2>

                <div style={{ marginBottom: "3em" }}>
                    <MediaCarousel items={items} />
                </div>

                {lead ? <_Blockquote align={"center"}>{lead}</_Blockquote> : null}
                <_processExternalLink href={link.href}>{link.title}</_processExternalLink>
            </Col>
        </Row>
    )

    return appState.isMobile ? mobileContent : desktopContent
}
