import * as React from "react"

import { _h3, _h2 } from "../../shared/headline.styles"
import { _Blockquote } from "../../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"
import { GenericItem } from "../../shared"
import { _customMarginRow } from "../../shared/row.styles"

interface OpportunitiesProps {
    title: string | JSX.Element
    lead?: string | JSX.Element
    elements: ElementProps[]
}

interface ElementProps {
    image: string
    title: string
    lead: any
    link?: {
        href: string
        title: string
    }
}

export const Opportunities: React.FC<OpportunitiesProps> = ({ title, elements, lead }) => {
    return (
        <>
            <Row center="xs">
                <Col lg={10} md={10} sm={12}>
                    <_h2 fontSize={42} fontSizeSm={36} maxWidth="620px" margin="auto">
                        {title}
                    </_h2>
                    {lead ? (
                        <_Blockquote marginLeft="auto" marginRight="auto" maxWidth="540px" align={"center"}>
                            {lead}
                        </_Blockquote>
                    ) : null}
                </Col>
            </Row>

            <_customMarginRow center="xs">
                {elements.map((element, index: number) => {
                    return (
                        <Col lg={4} md={12} sm={12} key={index}>
                            <GenericItem
                                title={element.title}
                                lead={element.lead}
                                image={element.image}
                                link={element.link}
                                cardImageClassName="smallImage"
                                itemMaxWidth="255px"
                            />
                        </Col>
                    )
                })}
            </_customMarginRow>
        </>
    )
}
