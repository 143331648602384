import * as React from "react"
import { Layout, _Section, HeadComponent } from "../components/shared"
import { graphql } from "gatsby"
import { Hero } from "../components/productsPage/shared"
import { prismicGetText, prismicGetRichText, extractPrismicNode, prismicGetRichHeader } from "../utils/prismicHelper"
import { PageWrapper } from "../utils/PageWrapper"
import { _h2 } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { Opportunities } from "../components/productsPage/Radar"
import { _externalLinkButton } from "../components/shared/buttons"
import dataImage from "../images/data.svg"

export const prismicQuery = graphql`
    {
        prismic {
            ...dataFragment
        }
    }
`

interface DataPageProps {
    data: {
        prismic: {
            allProduct_dialogues: {
                edges: any
            }
        }
    }
}

const DataPage: React.FC<DataPageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allDatas")
    if (!doc) return null

    const dataGroups = doc.data_group.map((item: any) => {
        return {
            image: item.image_datagroup.url,
            lead: prismicGetRichText(item.description_datagroup_image),
            title: prismicGetText(item.title_datagroup_image)
        }
    })

    return (
        <>
            <HeadComponent
                title="Data | Radicle"
                keywords={prismicGetText(doc.seo_keywords_data)}
                description={prismicGetText(doc.seo_description_data)}
            />
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero
                            description="Data"
                            title={prismicGetRichHeader(doc.title_data_v2)}
                            lead={prismicGetRichText(doc.lead_data)}
                            image={dataImage}
                            heroImage={{
                                isBlured: false,
                                height: "auto"
                            }}
                            leftSideLgWidth={6}
                            showImageOnMobile={false}
                        />
                    </_Section>

                    <_Section>
                        <Opportunities
                            title={prismicGetRichHeader(doc.title_datagroup_v2)}
                            lead={prismicGetRichText(doc.lead_datagroup)}
                            elements={dataGroups}
                        />
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default DataPage
