import * as React from "react"

import { _h2, _h3, _description } from "../../shared/headline.styles"
import { _Blockquote } from "../../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"
import { prismicGetText } from "../../../utils/prismicHelper"
import { _customMarginRow } from "../../shared/row.styles"
import { ImageCard, SliderSettings } from "../../shared/"
import Slider from "react-slick"
import { _externalLinkButton } from "../../shared/buttons"
import { AppStateContext } from "../../../utils/PageWrapper"
import styled from "styled-components"

interface ExampleRadarsProps {
    examples: ExampleItemProps[]
}

interface ExampleItemProps {
    radar_title1: any
    radar_image: any
    radar_type: any
    cta_label_radar: any
    radar_link: {
        url: string
    }
}

const _customRow = styled(Row)`
    display: flex !important;
    align-items: center;
`

export const ExampleRadars: React.FC<ExampleRadarsProps> = ({ examples }) => {
    const appState = React.useContext(AppStateContext)
    if (!appState.isReady) return null

    return (
        <>
            <_customMarginRow>
                <Col sm={12}>
                    <Slider {...SliderSettings({ slidesToShow: 1 })}>
                        {examples.map((example, index) => {
                            return (
                                <_customRow key={index}>
                                    {!appState.isMobile && (
                                        <Col lg={6} md={12} sm={12}>
                                            <ImageCard imageUrl={example.radar_image.url} />
                                        </Col>
                                    )}

                                    <Col lg={5} lgOffset={1} md={12} sm={12}>
                                        <_description align="left">{prismicGetText(example.radar_type)}</_description>
                                        <_h2 marginTop={"0"} fontSize={36} align="left">
                                            {prismicGetText(example.radar_title1)}
                                        </_h2>

                                        {appState.isMobile && <ImageCard imageUrl={example.radar_image.url} />}

                                        <_externalLinkButton marginTop={2} href={example.radar_link.url}>
                                            {prismicGetText(example.cta_label_radar)}
                                        </_externalLinkButton>
                                    </Col>
                                </_customRow>
                            )
                        })}
                    </Slider>
                </Col>
            </_customMarginRow>
        </>
    )
}
